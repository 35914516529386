<template>
  <div class="col-md-2 channel-cards my-2 ml-3" @click="informclick()">
    <div class="d-flex flex-column align-items-center">
      <img
        class="image_card "
        :src="picname"
        :alt="companyname"
        width="150px"
        height="80px"
      />
      <p class="company-name mb-0 ">{{ companyname }}</p>
      <p hidden>{{ companyId }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      image: "amazon-icon",
    };
  },
  props: ["picname", "companyname", "companyId"],
  methods: {
    // getImgUrl() {
    //   var images = require.context("../assets/", false, /\.png$/);
    //   var images = this.picname;
    //   return images;
    // },
    // getImgUrl() {
    //   return require("../assets/" + this.image + ".png");
    // },

    informclick() {
      this.$emit("clicked", this.companyId);
    },
  },
};
</script>

<style scoped>
.channel-cards {
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;
  border: solid 1px #cecece;
  background-color: #ffffff;
}
.company-name {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  color: #363535;
}
.image_card{
  width: auto;
  height: 70px;
  object-fit: contain;
}
</style>
