<template>
  <div>
    <!-- <div style="background: white">
      <div style="margin: 10px;">
        <span class="text-muted">CHANNEL ></span>
      </div>
      <span id="user" style="margin: 10px; ">CHANNEL</span>
    </div>-->
    <ViewChannel v-if="!var1 && !var2" @goToChannelType="update()" />
    <ChannelType v-if="!var1 && var2" @navigateToAddChannel="update()" />
    <Channel v-if="var1 && !var2" @goToViewChannel="update()" />
    <!-- <ViewChannel v-if="viewChannel"/> -->
  </div>
</template>
<script>
import Channel from "../components/Channels/Channel.vue";
import ViewChannel from "../components/Channels/ViewChannel.vue";
import ChannelType from "../components/Channels/ChannelType.vue";

export default {
  components: {
    Channel,
    ViewChannel,
    ChannelType,
  },
  data() {
    return {
      var1: false,
      var2: false,
    };
  },
  methods: {
    update() {
      if (!this.var1 && !this.var2) {
        this.var2 = true;
      } else if (!this.var1 && this.var2) {
        this.var1 = true;
        this.var2 = false;
      } else if (this.var1 && !this.var2) {
        this.var1 = false;
      }
      console.log("var1", this.var1, "--var2", this.var2);
      this.$forceUpdate();
    },
    // goToChannelTypeO() {
    //   this.channelType = true;
    // },
    // goToViewChannelO() {
    //   console.log('going')
    // this.viewChannel= true;
    // this.addChannel = false;
    // this.channelType = false;
    // },
    // navigateToAddChannelO() {
    //   this.addChannel = true;
    // }
  },
};
</script>

<style scoped></style>
