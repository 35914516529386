<template>
  <div class="pt-3">
    <div class="channel-types">
      <div class="d-flex justify-content-between">
        <p style="color: #090909; font-weight: 600">Add New Channel</p>
        <div style="cursor:pointer">
          <img src="../../assets/SVG/Back.svg" width="60px" @click="backToChannel" />
        </div>
      </div>
      <p style="color: ; font-weight: 600">Select Channel type</p>
      <b-card>
        <div class="row">
          <w-card
            v-for="item in carditems"
            :key="item"
            :companyname="item.name"
            :picname="item.pic"
            :companyId="item.id"
            @clicked="navigateToAddChannel(item.id)"
          />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import Cards from "../../widgets/Cards.vue";
import { getChannelType } from "../../Service/ChannelServiceApi";

export default {
  components: {
    "w-card": Cards,
  },
  data() {
    return {
      carditems: [],
    };
  },
  created() {
    this.getMarketPlaceType();
  },
  methods: {
    // back to channel page
    backToChannel() {
      this.$router.push("/ViewChannel");
    },

    navigateToAddChannel(id) {
        this.$router.push({ path: "/channel/new?id=" + id });
    },

    getMarketPlaceType() {
      this.carditems = [];
      getChannelType().then((res) => {
        res.sort(
          (a, b) => parseFloat(a.marketplaceId) - parseFloat(b.marketplaceId)
        ),
          res.map((element) => {
            this.carditems.push({
              name: element.marketplaceName,
              pic: element.marketplaceLogoUrl,
              id: element.marketplaceId,
            });
          });
      });
    },
  },
};
</script>
<style scoped>
.channel-types {
  /* margin: 0 60px; */
  margin: 0rem 55px 40px 60px;
}

@media screen and (max-width: 768px) {
  .channel-types {
    margin: 0 10px;
    margin-top: -50px;
  }
  .channel-cards {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
</style>>
